import axios from "axios";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

function isAuthenticated() {
  return localStorage.getItem("loggedOn");
}

let routes = [
  {
    // will match everything
    path: "*",
    component: () => import("../views/404.vue"),
  },
  {
    path: "/",
    name: "Home",
    redirect: "/dashboard",
    meta: {
      allowAnonymous: false,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    layout: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
    meta: {
      allowAnonymous: false,
    },
  },
  {
    path: "/layout",
    name: "Layout",
    layout: "dashboard",
    component: () => import("../views/Layout.vue"),
    meta: {
      allowAnonymous: false,
    },
  },
  {
    path: "/tables",
    name: "Tables",
    layout: "dashboard",
    component: () => import("../views/Tables.vue"),
    meta: {
      allowAnonymous: false,
    },
  },
  {
    path: "/billing",
    name: "Billing",
    layout: "dashboard",
    component: () => import("../views/Billing.vue"),
    meta: {
      allowAnonymous: false,
    },
  },
  {
    path: "/rtl",
    name: "RTL",
    layout: "dashboard-rtl",
    meta: {
      layoutClass: "dashboard-rtl",
      allowAnonymous: false,
    },
    component: () => import("../views/RTL.vue"),
  },
  {
    path: "/Profile",
    name: "Profile",
    layout: "dashboard",
    meta: {
      layoutClass: "layout-profile",
      allowAnonymous: false,
    },
    component: () => import("../views/Profile.vue"),
  },
  {
    path: "/sign-in",
    name: "Sign-In",
    component: () => import("../views/Sign-In.vue"),
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: "/sign-up",
    name: "Sign-Up",
    meta: {
      layoutClass: "layout-sign-up",
      allowAnonymous: true,
    },
    component: () => import("../views/Sign-Up.vue"),
  },
  // our funny ones
  {
    path: "/Ticket",
    name: "Ticket",
    meta: {
      allowAnonymous: false,
    },
    component: () => import("../views/Ticket.vue"),
  },
];

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute(route, parentLayout = "default") {
  route.meta = route.meta || {};
  route.meta.layout = route.layout || parentLayout;

  if (route.children) {
    route.children = route.children.map((childRoute) =>
      addLayoutToRoute(childRoute, route.meta.layout)
    );
  }
  return route;
}

routes = routes.map((route) => addLayoutToRoute(route));

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
    return {
      x: 0,
      y: 0,
      behavior: "smooth",
    };
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta.allowAnonymous !== true && !isAuthenticated())
    next({ name: "Sign-In" });
  else next();
});

// periodically checks for logged in info with check-auth api endpoint
function checkIfLoggedOn() {
  if (router.currentRoute.meta.allowAnonymous) {
    return;
  }
  axios
    .get("/api/v1/check-auth")
    .then((response) => {
      localStorage.setItem("loggedOn", response.data.authenticated);
      if (response.data.authenticated === false) {
        router.push({ name: "Sign-In" }).catch((err) => {});
      }
    })
    .catch((error) => {
      localStorage.setItem("loggedOn", false);
      router.push({ name: "Sign-In" }).catch((err) => {});
    });
}

// interval to recheck login info
// TODO: update to bigger milisecond interval
setInterval(checkIfLoggedOn, 30000);
export default router;
